import React from "react";
import "./Hero.scss"
import HomeComputerImage from "../../assets/image/principal/png/Home-Computador.png";
import DashBoardImage from "../../assets/image/principal/png/NuvemFiscalHome2.png";
import ZoomImage from "../../components/ZoomImage";


const Hero = ({className,...rest}) => {
    return (
      <div className={`hero-section ${className}`} {...rest}>
        <div className="containers-section" >
          <div
            className="img-section"
            data-aos="fade-right"
            data-aos-delay="500"
          >
            <div className="texto-section">
              <h1 className="titulo">
                <span className="titulo-span">+Simples</span>
                <span className="titulo-span">+Rápido</span>
                <span className="titulo-span">+Barato</span>
              </h1>
              <br/>
               <p className="texto-section">
               Tudo o que você precisa em serviços fiscais e
               automação comercial em uma única API.
               </p>
               <br/>
                <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                   Comece Grátis
                 </a>
            </div>
            <img src={HomeComputerImage} alt=""/>
          </div>
           <div className="dashboard-image">
            <ZoomImage className='img-container' src={DashBoardImage} alt="" />
           </div>
        </div>
    </div>
  );
}


export default Hero;