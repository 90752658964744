import React from "react";

// import korp from "../../assets/image/principal/png/korp.webp";
import sacfiscal from "../../assets/image/principal/png/sacfiscal.png";
import acbr from "../../assets/image/principal/png/acbr.png";

const SingleBrand = ({image, link}) => {
  return (
    <>
      {/* Single Brand */}
      <div className="single-brand-logo mx-5 my-6">
        <a href={link} target="_blank"><img src={image} height="150" alt="" data-aos="fade-in" data-aos-once="true" /></a>
      </div>
      {/* Single Brand */}
    </>
  );
}

const Parceiros = ({ className, ...rest }) => {
    const brands = [
    //   { image: korp, link: "https://korp.com.br" },
    { image: acbr, link: "https://projetoacbr.com.br" },
    { image: sacfiscal, link: "https://sacfiscal.com.br" }
    ];
  return (
    <>
      {/* Company Section */}
      <div className={className} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-lg-8 text-center text-lg-center">
                <h5 className="font-size-6 font-weight-normal text-bali-gray">
                  Nossos parceiros
                </h5>
              </div>
            </div>
          </div>
          {/* Brand Logos */}
          <div className="d-flex align-items-center justify-content-center justify-content-lg-around flex-wrap">
            {brands.map((brand, index) => (
              <SingleBrand image={brand.image} link={brand.link} key={index} />
            ))}
          </div>
        </div>
      </div>
      {/* End Company Section */}
    </>
  );
};

export default Parceiros;
